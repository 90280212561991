import type { Except } from 'type-fest'
import type { FormHelperTextProps } from '@mui/material/FormHelperText'
import React from 'react'
import FormHelperText from '@mui/material/FormHelperText'

type ErrorMessageProps = Except<FormHelperTextProps, 'error' | 'children'> & {
    text: React.ReactNode | React.ReactNode[]
}

const ErrorMessage = ({ text, sx, ...props }: ErrorMessageProps): JSX.Element => (
    <FormHelperText
        error
        sx={{ '&.MuiFormHelperText-root': { marginLeft: '14px', marginTop: '4px' }, ...sx }}
        {...props}
    >
        {text}
    </FormHelperText>
)

export default ErrorMessage
