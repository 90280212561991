import {
    SELECTION_CHANGE_COMMAND,
    FORMAT_TEXT_COMMAND,
    $getSelection,
    $isRangeSelection,
    $createParagraphNode,
} from 'lexical'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { $setBlocksType } from '@lexical/selection'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import {
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
    $isListNode,
    ListNode,
} from '@lexical/list'

import { $createQuoteNode, $isHeadingNode } from '@lexical/rich-text'
import Toolbar from '@mui/material/Toolbar'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import IconButton from '@mui/material/IconButton'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlined from '@mui/icons-material/FormatUnderlined'
import FormatHyperLinkIcon from '@mui/icons-material/Link'
import Divider from '@mui/material/Divider'
import { getSelectedNode } from '../utils/getSelectedNode'
import { sanitizeUrl } from '../utils/url'

const LowPriority = 1

const ToolbarPlugin = (): JSX.Element => {
    const [editor] = useLexicalComposerContext()
    const toolbarRef = useRef(null)
    const [blockType, setBlockType] = useState('paragraph')
    const [isLink, setIsLink] = useState(false)
    const [isBold, setIsBold] = useState(false)
    const [isItalic, setIsItalic] = useState(false)
    const [isUnderline, setIsUnderline] = useState(false)

    const updateToolbar = useCallback(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode()
            const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
            const elementKey = element.getKey()
            const elementDOM = editor.getElementByKey(elementKey)
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode)
                    const type = parentList ? parentList.getTag() : element.getTag()
                    setBlockType(type)
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType()
                    setBlockType(type)
                }
            }
            // Update text format
            setIsBold(selection.hasFormat('bold'))
            setIsItalic(selection.hasFormat('italic'))
            setIsUnderline(selection.hasFormat('underline'))

            // Update links
            const node = getSelectedNode(selection)
            const parent = node.getParent()
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true)
            } else {
                setIsLink(false)
            }
        }
    }, [editor])

    useEffect(
        () =>
            mergeRegister(
                editor.registerUpdateListener(({ editorState }) => {
                    editorState.read(() => {
                        updateToolbar()
                    })
                }),
                editor.registerCommand(
                    SELECTION_CHANGE_COMMAND,
                    () => {
                        updateToolbar()
                        return false
                    },
                    LowPriority
                )
            ),
        [editor, updateToolbar]
    )

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
        }
    }, [editor, isLink])

    return (
        <Toolbar
            ref={toolbarRef}
            sx={{
                backgroundColor: 'common.white',
                borderTop: 1,
                borderColor: 'divider',
                minHeight: '36px',
                p: 0.25,
                gap: 0.5,
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
            }}
            disableGutters
            variant="dense"
        >
            <IconButton
                size="small"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
                }}
                sx={{
                    backgroundColor: isBold ? 'action.selected' : undefined,
                    color: isBold ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: isBold ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Bold"
            >
                <FormatBoldIcon />
            </IconButton>
            <IconButton
                size="small"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
                }}
                sx={{
                    backgroundColor: isItalic ? 'action.selected' : undefined,
                    color: isItalic ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: isItalic ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Italics"
            >
                <FormatItalicIcon />
            </IconButton>
            <IconButton
                size="small"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
                }}
                sx={{
                    backgroundColor: isUnderline ? 'action.selected' : undefined,
                    color: isUnderline ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: isUnderline ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Underline"
            >
                <FormatUnderlined />
            </IconButton>
            <Divider orientation="vertical" flexItem sx={{ mx: 0.25 }} />
            <IconButton
                size="small"
                onClick={() => {
                    if (blockType !== 'ul') {
                        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, void 0)
                    } else {
                        editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0)
                    }
                }}
                sx={{
                    backgroundColor: blockType === 'ul' ? 'action.selected' : undefined,
                    color: blockType === 'ul' ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: blockType === 'ul' ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Bulleted List"
            >
                <FormatListBulletedIcon />
            </IconButton>
            <IconButton
                size="small"
                onClick={() => {
                    if (blockType !== 'ol') {
                        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, void 0)
                    } else {
                        editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0)
                    }
                }}
                sx={{
                    backgroundColor: blockType === 'ol' ? 'action.selected' : undefined,
                    color: blockType === 'ol' ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: blockType === 'ol' ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Numbered List"
            >
                <FormatListNumberedIcon />
            </IconButton>
            <IconButton
                size="small"
                onClick={() => {
                    if (blockType !== 'quote') {
                        editor.update(() => {
                            const selection = $getSelection()

                            if ($isRangeSelection(selection)) {
                                $setBlocksType(selection, $createQuoteNode)
                            }
                        })
                    } else {
                        editor.update(() => {
                            const selection = $getSelection()

                            if ($isRangeSelection(selection)) {
                                $setBlocksType(selection, $createParagraphNode)
                            }
                        })
                    }
                }}
                sx={{
                    backgroundColor: blockType === 'quote' ? 'action.selected' : undefined,
                    color: blockType === 'quote' ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: blockType === 'quote' ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Quote"
            >
                <FormatQuoteIcon />
            </IconButton>
            <Divider orientation="vertical" flexItem sx={{ mx: 0.25 }} />
            <IconButton
                size="small"
                onClick={insertLink}
                className={`toolbar-item spaced ${isLink ? 'active' : ''}`}
                aria-label="Insert Link"
                sx={{
                    color: 'grey.600',
                    '&:hover': {
                        backgroundColor: blockType === 'quote' ? 'action.focus' : 'action.hover',
                    },
                }}
            >
                <FormatHyperLinkIcon />
            </IconButton>
        </Toolbar>
    )
}

export default ToolbarPlugin
