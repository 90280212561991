import type React from 'react'
import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical'

const FocusPlugin = ({ setHasFocus }: { setHasFocus: React.Dispatch<React.SetStateAction<boolean>> }): null => {
    const [editor] = useLexicalComposerContext()

    useEffect(
        () =>
            editor.registerCommand(
                BLUR_COMMAND,
                () => {
                    setHasFocus(false)
                    return false
                },
                COMMAND_PRIORITY_LOW
            ),
        [editor, setHasFocus]
    )

    useEffect(
        () =>
            editor.registerCommand(
                FOCUS_COMMAND,
                () => {
                    setHasFocus(true)
                    return false
                },
                COMMAND_PRIORITY_LOW
            ),
        [editor, setHasFocus]
    )

    return null
}

export default FocusPlugin
