import type React from 'react'
import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { $getRoot } from 'lexical'

const TextContentPlugin = ({ setHasText }: { setHasText: React.Dispatch<React.SetStateAction<boolean>> }): null => {
    const [editor] = useLexicalComposerContext()
    useEffect(
        () =>
            mergeRegister(
                editor.registerUpdateListener(({ editorState }) => {
                    editorState.read(() => {
                        const root = $getRoot()
                        return root.getTextContent().length ? setHasText(true) : setHasText(false)
                    })
                })
            ),
        [editor, setHasText]
    )
    return null
}

export default TextContentPlugin
