import type { RequireExactlyOne } from 'type-fest'
import React from 'react'
import { useSession } from 'next-auth/react'

type PermissionFeatureProps = RequireExactlyOne<
    {
        type: Enum.FeaturePermissionType
        types: Enum.FeaturePermissionType[]
        children: React.ReactNode | React.ReactNode[]
    },
    'types' | 'type'
>

const hasRequestedRoles = (
    types: Enum.FeaturePermissionType[] | undefined,
    permissions: Auth.UserFeaturePermissions | undefined
) => !!permissions && !!types?.length && types.some((r) => permissions[r])

const PermissionFeature = ({ type, types, children }: PermissionFeatureProps): JSX.Element | null => {
    const { data: session } = useSession()
    return hasRequestedRoles(Array.isArray(types) ? types : type && [type], session?.roles.featurePermissions) ? (
        <>{children}</>
    ) : null
}

export default PermissionFeature
