import type { LexicalEditor, BaseSelection } from 'lexical'
import type { Dispatch } from 'react'
import { $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import {
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_CRITICAL,
    COMMAND_PRIORITY_HIGH,
    COMMAND_PRIORITY_LOW,
    KEY_ESCAPE_COMMAND,
    SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { createPortal } from 'react-dom'

import IconButton from '@mui/material/IconButton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import { getSelectedNode } from '../utils/getSelectedNode'
import setFloatingElemPosition from '../utils/setFloatingElemPosition'
import { sanitizeUrl } from '../utils/url'

function FloatingLinkEditor({
    editor,
    isLink,
    setIsLink,
    anchorElem,
}: {
    editor: LexicalEditor
    isLink: boolean
    setIsLink: Dispatch<boolean>
    anchorElem: HTMLElement
}): JSX.Element {
    const editorRef = useRef<HTMLDivElement | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [linkUrl, setLinkUrl] = useState('')
    const [isEditMode, setEditMode] = useState(false)
    const [lastSelection, setLastSelection] = useState<BaseSelection | null>(null)

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection)
            const parent = node.getParent()
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL())
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL())
            } else {
                setLinkUrl('')
            }
        }
        const editorElem = editorRef.current
        const nativeSelection = window.getSelection()
        const { activeElement } = document

        if (editorElem === null) {
            return
        }

        const rootElement = editor.getRootElement()

        if (
            selection !== null &&
            nativeSelection !== null &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode) &&
            editor.isEditable()
        ) {
            const domRange = nativeSelection.getRangeAt(0)
            let rect
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild as HTMLElement
                }
                rect = inner.getBoundingClientRect()
            } else {
                rect = domRange.getBoundingClientRect()
            }

            setFloatingElemPosition(rect, editorElem, anchorElem)
            setLastSelection(selection)
        } else if (!activeElement || activeElement.className !== 'link-input') {
            if (rootElement !== null) {
                setFloatingElemPosition(null, editorElem, anchorElem)
            }
            setLastSelection(null)
            setEditMode(false)
            setLinkUrl('')
        }
    }, [anchorElem, editor])

    useEffect(() => {
        const scrollerElem = anchorElem.parentElement

        const update = () => {
            editor.getEditorState().read(() => {
                updateLinkEditor()
            })
        }

        window.addEventListener('resize', update)

        if (scrollerElem) {
            scrollerElem.addEventListener('scroll', update)
        }

        return () => {
            window.removeEventListener('resize', update)

            if (scrollerElem) {
                scrollerElem.removeEventListener('scroll', update)
            }
        }
    }, [anchorElem.parentElement, editor, updateLinkEditor])

    useEffect(
        () =>
            mergeRegister(
                editor.registerUpdateListener(({ editorState }) => {
                    editorState.read(() => {
                        updateLinkEditor()
                    })
                }),

                editor.registerCommand(
                    SELECTION_CHANGE_COMMAND,
                    () => {
                        updateLinkEditor()
                        return true
                    },
                    COMMAND_PRIORITY_LOW
                ),
                editor.registerCommand(
                    KEY_ESCAPE_COMMAND,
                    () => {
                        if (isLink) {
                            setIsLink(false)
                            return true
                        }
                        return false
                    },
                    COMMAND_PRIORITY_HIGH
                )
            ),
        [editor, updateLinkEditor, setIsLink, isLink]
    )

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor()
        })
    }, [editor, updateLinkEditor])

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isEditMode])

    return (
        <Box ref={editorRef} className="link-editor">
            {isEditMode ? (
                <>
                    <input
                        ref={inputRef}
                        className="link-input"
                        value={linkUrl}
                        onChange={(event) => {
                            setLinkUrl(event.target.value)
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === 'Escape') {
                                event.preventDefault()
                                if (lastSelection !== null) {
                                    if (linkUrl !== '') {
                                        editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
                                            url: sanitizeUrl(linkUrl),
                                            rel: 'noopener',
                                            target: '_blank',
                                        })
                                    }
                                    setEditMode(false)
                                }
                            }
                        }}
                    />
                    <IconButton
                        sx={{ position: 'absolute', right: 12, top: 8 }}
                        size="small"
                        tabIndex={0}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => setEditMode(false)}
                    >
                        <CheckCircleIcon />
                    </IconButton>
                </>
            ) : (
                <Box className="link-input">
                    <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                        {linkUrl}
                    </a>
                    <IconButton
                        className="link-edit"
                        size="small"
                        tabIndex={0}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            setEditMode(true)
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    )
}

function useFloatingLinkEditor(editor: LexicalEditor, anchorElem: HTMLElement): JSX.Element | null {
    const [activeEditor, setActiveEditor] = useState(editor)
    const [isLink, setIsLink] = useState(false)

    const updateToolbar = useCallback(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection)
            const linkParent = $findMatchingParent(node, $isLinkNode)
            const autoLinkParent = $findMatchingParent(node, $isAutoLinkNode)

            // We don't want this menu to open for auto links.
            if (linkParent != null && autoLinkParent == null) {
                setIsLink(true)
            } else {
                setIsLink(false)
            }
        }
    }, [])

    useEffect(
        () =>
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, newEditor) => {
                    updateToolbar()
                    setActiveEditor(newEditor)
                    return false
                },
                COMMAND_PRIORITY_CRITICAL
            ),
        [editor, updateToolbar]
    )

    return isLink
        ? createPortal(
              <FloatingLinkEditor
                  editor={activeEditor}
                  isLink={isLink}
                  anchorElem={anchorElem}
                  setIsLink={setIsLink}
              />,
              anchorElem
          )
        : null
}

export default function FloatingLinkEditorPlugin({
    anchorElem = document.body,
}: {
    anchorElem?: HTMLElement
}): JSX.Element | null {
    const [editor] = useLexicalComposerContext()

    return useFloatingLinkEditor(editor, anchorElem)
}
