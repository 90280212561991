import type { FormikState } from 'formik'
import type { ToastContextInterface } from '../../lib/contexts/ToastContext'
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'

export const isSubmitButtonDisabled = ({
    isSubmitting,
    dirty,
    isValid,
    submitCount,
}: {
    isSubmitting: boolean
    dirty: boolean
    isValid: boolean
    submitCount: number
}): boolean => isSubmitting || !dirty || (!isValid && submitCount > 0)

export const scrollToError = <T extends object>(formErrors: T): void => {
    const errors = Object.keys(formErrors)
    if (errors.length) {
        const field = document.getElementById(errors[0])
        setTimeout(
            () =>
                field?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'start',
                }),
            0
        )
    }
}

export const handleTouchTap = (
    e: React.TouchEvent<HTMLDivElement>,
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>,
    expanded: boolean
): void => {
    if (e.cancelable) setExpanded(!expanded)
}

export function handleSuccess(toastContext: ToastContextInterface | null, successMessage: string): void
export function handleSuccess(
    toastContext: ToastContextInterface | null,
    successMessage: string,
    router: AppRouterInstance,
    route: string
): void
export function handleSuccess<T extends Pick<DTO.CreatePost, 'status'>>(
    toastContext: ToastContextInterface | null,
    successMessage: string,
    router: AppRouterInstance,
    resetForm: (nextState: Partial<FormikState<T>>) => void,
    values: T
): void
export function handleSuccess<T extends Pick<DTO.CreatePost, 'status'>>(
    toastContext: ToastContextInterface | null,
    successMessage: string,
    router?: AppRouterInstance,
    routeOrResetForm?: string | ((nextState: Partial<FormikState<T>>) => void),
    values?: T
): void {
    const route = routeOrResetForm && (typeof routeOrResetForm === 'string' ? routeOrResetForm : '/')
    if (values && typeof routeOrResetForm === 'function') {
        routeOrResetForm({ values: { ...values, status: 'ACTIVE' } })
    }
    toastContext?.addToast({
        severity: 'success',
        message: successMessage,
        duration: route ? 5000 : 3000,
    })
    if (router && route) {
        router.push(route)
    }
}

export const advancedPermissions: Enum.FeaturePermissionType[] = [
    'post-date-management',
    'author-management',
    'audience-management',
]
