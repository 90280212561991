/* eslint-disable react/require-default-props */
import type { EditorState, LexicalEditor } from 'lexical'
import { $isDecoratorNode, $isElementNode, CLEAR_EDITOR_COMMAND, $createParagraphNode, $getRoot } from 'lexical'
import React, { useEffect, useRef, useMemo, useState, useCallback, forwardRef, useImperativeHandle } from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { QuoteNode } from '@lexical/rich-text'
import { ListItemNode, ListNode } from '@lexical/list'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import debounce from 'lodash.debounce'
import ErrorMessage from '../ErrorMessage'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import EditorTheme from './themes/EditorTheme'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import FocusPlugin from './plugins/FocusPlugin'
import TextContentPlugin from './plugins/TextContentPlugin'
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin'
import { validateUrl } from './utils/url'
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin'
import './Editor.css'
// import TreeViewPlugin from './plugins/TreeViewPlugin'
// ^extension for debugging

export type EditorProps = DTO.AttachmentInfo & {
    onChange: (html: string | null) => void
    label: string
    id: string
    placeholder?: string
    required?: boolean
    error?: boolean | undefined
    minHeight?: number
    maxHeight?: number
    initialValue?: string
    focusOnInitialization?: boolean
    errorText?: string
}

const Placeholder = ({ text }: { text: string | undefined }) => (
    <Box
        sx={{
            color: '#939393',
            overflow: 'hidden',
            position: 'absolute',
            textOverflow: 'ellipsis',
            top: '8.5px',
            left: '14px',
            userSelect: 'none',
            display: 'inlineBlock',
            pointerEvents: 'none',
        }}
    >
        {text || 'Type Comments...'}
    </Box>
)

const onEditorChange = (_: EditorState, editor: LexicalEditor, onChange: (html: string | null) => void) => {
    editor.update(() => {
        const html = $generateHtmlFromNodes(editor, null)

        return $getRoot().getTextContent().length ? onChange(html) : onChange(null)
    })
}

const RefAssignerPlugin = ({
    editorRef,
}: {
    editorRef: React.MutableRefObject<LexicalEditor | null | undefined> | null
}) => {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        if (editorRef) {
            // eslint-disable-next-line no-param-reassign
            editorRef.current = editor
        }
    }, [editor, editorRef])

    return null
}

export type EditorElement = { clearEditorContent: () => void }
const Editor = forwardRef<EditorElement, EditorProps>(
    (
        {
            onChange,
            label,
            placeholder,
            required,
            error,
            initialValue,
            minHeight = 200,
            maxHeight = 200,
            focusOnInitialization,
            id,
            errorText,
        },
        ref
    ): JSX.Element => {
        const editorRef = useRef<LexicalEditor | null>()
        const [hasFocus, setHasFocus] = useState(false)
        const [hasText, setHasText] = useState(false)
        const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)

        const onRef = (_floatingAnchorElem: HTMLDivElement | null) => {
            if (_floatingAnchorElem !== null) {
                setFloatingAnchorElem(_floatingAnchorElem)
            }
        }

        const expandInputLabel = hasFocus || hasText

        const debouncedOnchange = useMemo(() => debounce(onChange, 600), [onChange])

        const HTMLToLexical = (editor: LexicalEditor, value: string, clear: boolean) => {
            const root = $getRoot()
            const parser = new DOMParser()
            const dom = parser.parseFromString(value, 'text/html')
            const nodes = $generateNodesFromDOM(editor, dom)
            if (clear) {
                root.clear()
            }
            nodes.forEach((node) => {
                if ($isElementNode(node) || $isDecoratorNode(node)) {
                    root.append(node)
                } else {
                    root.append($createParagraphNode().append(node))
                }
            })
        }

        const prepopulatedRichText = useCallback(
            (editor: LexicalEditor) => {
                editorRef.current = editor
                if (!initialValue) {
                    $getRoot().append($createParagraphNode())
                } else {
                    HTMLToLexical(editor, initialValue, true)
                }
                return null
            },
            [initialValue]
        )

        useEffect(() => {
            if (focusOnInitialization) {
                editorRef.current?.update(() => $getRoot().selectEnd())
            }
        }, [focusOnInitialization])

        useImperativeHandle(ref, () => ({
            clearEditorContent() {
                editorRef.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
            },
        }))

        const editorConfig = useMemo(
            () => ({
                editorState: prepopulatedRichText,
                namespace: id,
                theme: EditorTheme,
                onError(e: Error) {
                    throw e
                },
                // Any custom nodes go here
                nodes: [ListNode, ListItemNode, QuoteNode, AutoLinkNode, LinkNode],
            }),
            [id, prepopulatedRichText]
        )

        return (
            <>
                <LexicalComposer initialConfig={editorConfig}>
                    <FormControl
                        fullWidth
                        required={required}
                        size="small"
                        sx={{
                            boxSizing: 'border-box',
                            borderRadius: 1,
                            borderWidth: hasFocus ? '2px' : '1px',
                            borderStyle: 'solid',
                            borderColor: error ? 'error.main' : hasFocus ? 'primary.main' : 'rgba(0, 0, 0, .23)',
                            position: 'relative',
                            '&:hover': {
                                borderColor: error ? 'error.main' : hasFocus ? 'primary.main' : 'text.primary',
                            },
                            '.editor-input': {
                                maxHeight,
                                minHeight,
                            },
                        }}
                        variant="outlined"
                        focused={hasFocus}
                        error={error}
                    >
                        <InputLabel
                            id={id}
                            required={required}
                            shrink={expandInputLabel}
                            sx={{
                                backgroundColor: 'common.white',
                                paddingLeft: expandInputLabel ? 1 : undefined,
                                paddingRight: expandInputLabel ? 1 : undefined,
                            }}
                        >
                            {label}
                        </InputLabel>
                        <RichTextPlugin
                            contentEditable={
                                <Box className="editor-inner" ref={onRef}>
                                    <ContentEditable className="editor-input" spellCheck />
                                </Box>
                            }
                            placeholder={hasFocus ? <Placeholder text={placeholder} /> : null}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <RefAssignerPlugin editorRef={editorRef} />
                        <OnChangePlugin
                            onChange={(editorState, editor) => {
                                onEditorChange(editorState, editor, debouncedOnchange)
                            }}
                        />
                        <HistoryPlugin />
                        <ListPlugin />
                        <FocusPlugin setHasFocus={setHasFocus} />
                        <TextContentPlugin setHasText={setHasText} />
                        <LinkPlugin validateUrl={validateUrl} />
                        {floatingAnchorElem && <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />}
                        <AutoLinkPlugin />
                        <TabIndentationPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={3} />
                        <ClearEditorPlugin />
                        {/* <TreeViewPlugin /> */}
                        <ToolbarPlugin />
                    </FormControl>
                </LexicalComposer>
                {error && <ErrorMessage text={errorText || 'Text is required'} />}
            </>
        )
    }
)

export default Editor
