const VERTICAL_GAP = 10
const HORIZONTAL_OFFSET = 5

const setFloatingElemPosition = (
    targetRect: DOMRect | null,
    floatingElem: HTMLElement,
    anchorElem: HTMLElement,
    verticalGap: number = VERTICAL_GAP,
    horizontalOffset: number = HORIZONTAL_OFFSET
): void => {
    const scrollerElem = anchorElem.parentElement
    const adjustedFloatingElem = floatingElem

    if (targetRect === null || !scrollerElem) {
        adjustedFloatingElem.style.opacity = '0'
        adjustedFloatingElem.style.transform = 'translate(-10000px, -10000px)'
        return
    }

    const floatingElemRect = adjustedFloatingElem.getBoundingClientRect()
    const anchorElementRect = anchorElem.getBoundingClientRect()
    const editorScrollerRect = scrollerElem.getBoundingClientRect()

    let top = targetRect.top - floatingElemRect.height - verticalGap
    let left = targetRect.left - horizontalOffset

    if (top < editorScrollerRect.top) {
        top += floatingElemRect.height + targetRect.height + verticalGap * 2
    }

    if (left + floatingElemRect.width > editorScrollerRect.right) {
        left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset
    }

    top -= anchorElementRect.top
    left -= anchorElementRect.left

    adjustedFloatingElem.style.opacity = '1'
    adjustedFloatingElem.style.transform = `translate(${left}px, ${top}px)`
}

export default setFloatingElemPosition
