import type { useFormik } from 'formik'
import React, { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import Link from '@/components/Link'
import ConfirmationDialog from '@/components/dialog/ConfirmationDialog'
import ImageWithFallback from '@/components/ImageWithFallback'

export const ImageAttachment = ({ attachment }: { attachment: Pick<DTO.Attachment, 'url' | 'name'> }): JSX.Element => (
    <>
        <Box
            sx={{
                position: 'relative',
                height: 50,
                width: 50,
            }}
        >
            <ImageWithFallback alt="image attachment" src={attachment.url} fallbackSize={{ sm: 35 }} priority />
        </Box>
        <Link href={attachment.url} {...{ target: '_blank', rel: 'noopener noreferrer' }}>
            {attachment.name}
        </Link>
    </>
)

const PDFAttachment = ({ attachment }: { attachment: Pick<DTO.Attachment, 'url' | 'name'> }): JSX.Element => (
    <>
        <Box sx={{ position: 'relative', height: 50, width: 100 }}>
            <object data={attachment.url} type="application/pdf" width="100%" height="100%">
                <p>
                    <a href={attachment.url}>{attachment.name}</a>
                </p>
            </object>
        </Box>
        <Link href={attachment.url} {...{ target: '_blank', rel: 'noopener noreferrer' }}>
            {attachment.name}
        </Link>
    </>
)

export const renderAttachment = (attachment: DTO.Attachment): JSX.Element => {
    if (attachment.mimeType.includes('image')) {
        return <ImageAttachment attachment={attachment} />
    }
    if (attachment.mimeType === 'application/pdf') {
        return <PDFAttachment attachment={attachment} />
    }
    return (
        <Link href={attachment.url} {...{ target: '_blank', rel: 'noopener noreferrer' }}>
            {attachment.name}
        </Link>
    )
}

type AttachmentListProps = {
    attachments: DTO.Attachment[]
    setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
    setIsFormChanged?: React.Dispatch<React.SetStateAction<boolean>>
    isSubmitting?: boolean
    fieldName: string
}
const AttachmentList = ({
    attachments,
    isSubmitting,
    setFieldValue,
    setIsFormChanged,
    fieldName,
}: AttachmentListProps): JSX.Element => {
    const onRemove = useCallback(
        async (attachmentId: string) => {
            const filteredAttachments = attachments.filter((a) => a.attachmentId !== attachmentId)
            await setFieldValue(fieldName, filteredAttachments)
            if (setIsFormChanged) setIsFormChanged(true)
        },
        [setFieldValue, setIsFormChanged, attachments, fieldName]
    )
    return (
        <Grid item xs={12}>
            <table>
                <tbody>
                    {attachments.map((a, idx) => (
                        <tr key={a.attachmentId}>
                            <Box component="td" sx={{ pr: 1 }}>
                                {idx + 1}.
                            </Box>
                            <td>{renderAttachment(a)}</td>
                            <Box component="td" sx={{ pl: 4 }}>
                                <ConfirmationDialog
                                    trigger={
                                        <IconButton disabled={isSubmitting} name={a.attachmentId} size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    }
                                    headerText={
                                        <>
                                            Remove{' '}
                                            <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                                                {a.name}
                                            </Box>
                                            ?
                                        </>
                                    }
                                    confirmText="Delete"
                                    handleConfirm={() => onRemove(a.attachmentId)}
                                    deletion
                                />
                            </Box>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Grid>
    )
}

export default AttachmentList
