import React, { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'

type ConfirmationDialogProps = {
    trigger: JSX.Element
    handleConfirm: () => void | Promise<unknown>
    headerText: React.ReactNode
    confirmText: string
    deletion?: boolean
}

const ConfirmationDialog = ({
    trigger,
    headerText,
    confirmText,
    handleConfirm,
    deletion = true,
}: ConfirmationDialogProps): JSX.Element => {
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onConfirm = useCallback(async () => {
        setIsLoading(true)
        await handleConfirm()
        setOpen(false)
        setIsLoading(false)
    }, [setOpen, setIsLoading, handleConfirm])

    const Trigger = React.cloneElement(trigger, {
        onClick: () => setOpen(true),
    })
    return (
        <>
            {Trigger}
            <Dialog
                sx={{ '& .MuiDialog-paper': { minWidth: 250, maxHeight: 435 } }}
                maxWidth="xs"
                open={open}
                keepMounted
            >
                <DialogContent>
                    <Typography variant="body1" fontWeight="medium">
                        {headerText}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: 'text.secondary' }} autoFocus onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{ color: deletion ? 'error.light' : 'primary.main' }}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onConfirm}
                    >
                        {confirmText}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmationDialog
